<template>
  <div class="row mb-3 text-center">
    <div class="col-md-8 themed-grid-col">
      <h1>Rezepte : <i class="bi-node-plus" @click="addRezept()"></i></h1>
      <h2>{{ this.aktRezept.name }}</h2>
      <input type="text" v-model="this.aktRezept.name" :readonly="isBearbeitbar()" />
      <h3>Zubereitung :
        <i class="bi-trash"></i>
        <i class="bi-node-plus" @click="addZubereitung()"></i>
      </h3>

      <div class="pb-3">
        <ul v-for="(zubereit, index) in this.aktRezept.zubereitung" :key="index">
          <li>{{ zubereit }}</li>
        </ul>
      </div>
      <div class="row">
        <div class="col-md-6 themed-grid-col">
          <h4>Zutaten :
            <i class="bi-node-plus" @click="addZutat()"></i>
          </h4>
          <ul v-for="(zutat, index) in this.aktRezept.zutaten" :key="index">
            <li>
              <input type="text" v-model="this.aktRezept.zutaten[index]" />
              <i class="bi-trash" @click="removeZutat(index)"></i>
            </li>
          </ul>
        </div>
        <div class="col-md-6 themed-grid-col">
          <h4>Tipps & Tricks :</h4>
          <div><textarea :readonly="isBearbeitbar()" v-model="this.aktRezept.anmerkung"></textarea></div>
        </div>
      </div>
    </div>
    <div class="col-md-4 themed-grid-col">
      <div class="alert alert-secondary" v-for="rezept in bestRezepte" :key="rezept.id" @click="setAkt(rezept)">
        {{ rezept.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      rezepte: [
        {
          id: 1,
          name: "Apfelkuchen",
          zubereitung: ["Mach mal ...", "Backofen auf 1700°C vorheizen"],
          zutaten: ["500g Äpfel (Boskop)", "300g Mehl", "200g Zucker"],
          anmerkung: "Aller klar ?",
          bewertung: 1,
        },
        {
          id: 2,
          name: "Zwetschgenkuchen",
          zubereitung: ["Mach mal ...", "Backofen auf 1700°C vorheizen", "Kuchen aus Form kratzen"],
          zutaten: ["500g Zwetschgen", "300g Mehl", "200g Zucker"],
          anmerkung: "Aller klar ?",
          bewertung: 1,
        },
      ],
      aktRezept: {
        id: 2,
        name: "Zwetschgenkuchen",
        zubereitung: ["Mach mal ...", "Backofen auf 1700°C vorheizen"],
        zutaten: ["500g Zwetschgen", "300g Mehl", "200g Zucker"],
        anmerkung: "Aller klar ?",
        bewertung: 1,
      },
      isReadonly: true,
      nextId: 3,
    };
  },
  computed: {
    bestRezepte() {
      console.log("bestRezepte");
      console.log(this.rezepte);
      //return this.rezepte.filter((rezept) => rezept.bewertung < 3);
      return this.rezepte;
    },
  },
  mounted() {
    console.log("mounted");
    this.loadRezepte();
  },
  methods: {
    setAkt(rez) {
      this.aktRezept = rez;
    },
    isBearbeitbar() {
      return !this.isReadonly;
    },
    addRezept() {
      console.log(this.rezepte);
      /*       this.rezepte.push({
              id: this.nextId,
              name: "?",
              zubereitung: [],
              zutaten: [],
              anmerkung: "",
              bewertung: 1,
            });
            this.nextId++; */
    },
    addZubereitung() {
      this.aktRezept.zubereitung.push("Neu");
      this.aktRezept.zubereitung.sort();
    },
    addZutat() {
      this.aktRezept.zutaten.push("Neu");
      this.aktRezept.zutaten.sort();
    },
    removeZutat(index) {
      this.aktRezept.zutaten.splice(index, 1);
      this.aktRezept.zutaten.sort();
    },
    loadRezepte() {
      console.log("Rezepte laden");
      fetch("https://luz/localphp/show.php?table=rezept&auth=765198666,573419818,412568550,-196795232,-45473808,-379295378,1772061183,-1168965682//tolu")
        .then(function (response) {
          console.log(response);
          console.log(this.rezepte);
          response.json();
        })
      this.rezepte = loadData();
    }
  },
};
async function loadData() {
  const url = "https://luz/localphp/show.php?table=rezept&auth=765198666,573419818,412568550,-196795232,-45473808,-379295378,1772061183,-1168965682//tolu";
  const response = await fetch(url);
  const data = await response.json();
  console.log(" load wird aufgerufen: " + url);
  if (data.length > 0) {
    return data;
  }
}
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";

h1 {
  text-align: center
}

;
</style>
